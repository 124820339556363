
import { defineComponent } from 'vue';

/**
 * Lodash
 * @see https://lodash.com/docs/
 */
import _find from 'lodash/find';

import api from '@/api';

// interfaces
import { Page as BasePage } from '@/interfaces/Page';
import { Layer as BaseLayer } from '@/interfaces/Layer';

interface InputFileEvent extends Event {
  target: HTMLInputElement,
}

interface ComboboxItem {
  id: string,
  title: string,
}

interface Action {
  label: string,
  key: string,
  item: (ComboboxItem | null),
}

interface Layer extends BaseLayer {
  edit: boolean,
}

interface Page extends BasePage {
  collapsed: boolean,
}

interface Validator {
  validate(): Promise<{ valid: boolean }>;
}

const styleToObject = (style: string): { [key: string]: string } => {
  const s = {} as { [key: string]: string };

  style.split(';')
    .forEach((pair) => {
      const [key = '', value = ''] = pair.split('=');
      s[key] = value;
    });

  return s;
};

const styleToString = (style: { [key: string]: string }): string => Object.entries(style)
  .map(([key, value]) => ((value) ? `${key}=${value}` : key))
  .join(';');

const hideElement = (el: Element): void => {
  const mxCell = (el.tagName === 'mxCell') ? el : el.querySelector('mxCell');

  if (mxCell) {
    const style = styleToObject(mxCell.getAttribute('style') || '');

    style.opacity = '0';
    style.noLabel = '1';

    mxCell.setAttribute('style', styleToString(style));
  }
};

const showElement = (el: Element): void => {
  const mxCell = (el.tagName === 'mxCell') ? el : el.querySelector('mxCell');

  if (mxCell) {
    const style = styleToObject(mxCell.getAttribute('style') || '');

    style.opacity = '100';
    style.noLabel = '0';

    mxCell.setAttribute('style', styleToString(style));
  }
};

const getRootChildElement = (el: Element): Element => ((!el.parentElement || el.parentElement.tagName === 'root')
  ? el
  : getRootChildElement(el.parentElement));

const randomId = (doc: Document, length = 20): string => {
  const id = Math.random()
    .toString(36)
    .substring(2, length + 2);

  return (doc.getElementById(id)) ? randomId(doc, length) : id;
};

export default defineComponent({
  /**
   * The name of component
   * @see https://ua.vuejs.org/api/options-misc.html#name
   */
  name: 'HomeView',

  /**
   * The components that the component can use.
   * @see  https://ua.vuejs.org/api/options-misc.html#components
   */
  components: {},

  /**
   * The options that can be used by the component
   * @returns {Object} The view-model options.
   */
  LABS64_PREFIX: 'labs64',
  PAGE_TITLE: 'pageTitle',
  NEXT_PAGE: 'nextPage',
  PREV_PAGE: 'prevPage',
  NEXT_LAYER: 'nextLayer',
  PREV_LAYER: 'prevLayer',
  HIDE_LAYERS: 'hideLayers',
  SHOW_LAYERS: 'showLayers',
  fileInputRules: [(v: Array<File>) => !!v.length || 'File is required'],
  pageRules: [(v: string) => !!v || 'Page is required'],
  layerRules: [(v: string) => !!v || 'Layer is required'],

  /**
   * The data that can be used by the component.
   * @returns {Object} The view-model data.
   */
  data() {
    return {
      loading: false,

      error: null as (string | null),

      file: null as (File | null),

      drawIODoc: null as (Document | null),

      rootDiagramElements: [] as Array<ComboboxItem>,

      actions: [
        {
          label: 'Page Title',
          key: this.$options.PAGE_TITLE,
          item: null,
        },
        {
          label: 'Next Page',
          key: this.$options.NEXT_PAGE,
          item: null,
        },
        {
          label: 'Previous Page',
          key: this.$options.PREV_PAGE,
          item: null,
        },
        {
          label: 'Next Layer',
          key: this.$options.NEXT_LAYER,
          item: null,
        },
        {
          label: 'Prev Layer',
          key: this.$options.PREV_LAYER,
          item: null,
        },
        {
          label: 'Hide Layers',
          key: this.$options.HIDE_LAYERS,
          item: null,
        },
        {
          label: 'Show Layers',
          key: this.$options.SHOW_LAYERS,
          item: null,
        },
      ] as Array<Action>,

      diagrams: [] as Array<Page>,

      step: 1,
    };
  },

  /**
   * Computed Properties
   * @see https://vuejs.org/v2/guide/computed.html#Computed-Properties
   */
  computed: {
    actionItems(): Array<ComboboxItem> {
      return this.rootDiagramElements.filter((item) => !_find(this.actions, { item }));
    },

    isLayersVisible(): boolean {
      return this.diagrams.some((diagram) => diagram.layers.some((layer) => layer.visible));
    },

    isLayersLocked(): boolean {
      return this.diagrams.some((diagram) => diagram.layers.some((layer) => layer.locked));
    },
  },

  /**
   * The methods the component can use.
   * @see https://ua.vuejs.org/api/options-state.html#methods
   */
  methods: {
    reset() {
      this.file = null;
      this.drawIODoc = null;
      this.diagrams = [];
      this.rootDiagramElements = [];
      this.actions.forEach((v: Action) => {
        // eslint-disable-next-line no-param-reassign
        v.item = null;
      });
    },

    async toNextStep() {
      const form: Validator | undefined = this.$refs[`form${this.step}`] as Validator;

      if (form) {
        const { valid } = await form.validate();

        if (!valid) {
          return;
        }
      }

      this.step += 1;
    },

    toPreviousStep() {
      this.step -= 1;
    },

    async onFileChange(event: InputFileEvent): Promise<void> {
      this.loading = true;

      const { target: { files } } = event;

      this.reset();

      if (!files) {
        return;
      }

      const file = files[0];

      if (file) {
        try {
          const drawIODoc = await this.readFile(file);

          this.validateFile(drawIODoc);

          this.file = file;
          this.drawIODoc = drawIODoc;

          // parse pages and layers
          const diagramEls = drawIODoc.querySelectorAll('diagram');

          diagramEls.forEach((diagramEl) => {
            const layerEls: Array<Element> = Array.from(diagramEl.querySelectorAll('[parent="0"]'))
              .filter((layerEl) => layerEl.id !== '1');

            if (!diagramEl.id) {
              // eslint-disable-next-line no-param-reassign
              diagramEl.id = randomId(drawIODoc);
            }

            const diagram = this.addDiagram({
              title: diagramEl.getAttribute('name') || '',
              id: diagramEl.id,
            });

            layerEls.forEach((layerEl, index) => {
              const layerRootEl = getRootChildElement(layerEl);

              const isLayerMXCell = layerEl.tagName === 'mxCell';

              const layerMxCell = isLayerMXCell
                ? layerEl
                : layerRootEl.querySelector('mxCell');

              let title: string | null = (layerMxCell !== layerRootEl)
                ? layerRootEl?.getAttribute('label')
                : layerMxCell?.getAttribute('value');

              title = title || `Layer ${index}`;

              const visible = (layerMxCell?.getAttribute('visible') === '1');
              const style = styleToObject(layerMxCell?.getAttribute('style') || '');
              const locked = (style.locked === '1');

              if (!layerRootEl.id) {
                // eslint-disable-next-line no-param-reassign
                layerRootEl.id = randomId(drawIODoc);
              }

              this.addLayer(diagram, {
                title,
                visible,
                locked,
                id: layerRootEl.id,
              });
            });
          });

          if (this.diagrams.length) {
            const [rootDiagram] = this.diagrams;

            if (!rootDiagram.id) {
              return;
            }

            const rootDiagramEl = drawIODoc.getElementById(rootDiagram.id);

            if (!rootDiagramEl) {
              return;
            }

            Array.from(rootDiagramEl
              .querySelectorAll('[parent="1"]'))
              .forEach((el) => {
                const rootChildElement = getRootChildElement(el);

                let title = (rootChildElement.tagName === 'mxCell')
                  ? rootChildElement.getAttribute('value') || ''
                  : rootChildElement.getAttribute('label') || '';

                const originalLabel = rootChildElement.getAttribute('original-label');

                if (originalLabel) {
                  title = (title && title !== originalLabel)
                    ? `${originalLabel} [${title}]`
                    : originalLabel;
                }

                if (!title) {
                  title = `- no label - [ID: ${rootChildElement.id}]`;
                }

                if (!rootChildElement.id) {
                  rootChildElement.id = randomId(drawIODoc);
                }

                const rootDiagramItem = {
                  title,
                  id: rootChildElement.id,
                };

                this.rootDiagramElements.push(rootDiagramItem);

                const labs64Key = rootChildElement.getAttribute(this.$options.LABS64_PREFIX);

                if (labs64Key) {
                  const action = _find(this.actions, { key: labs64Key }) as Action;

                  if (action) {
                    action.item = rootDiagramItem;
                  }
                }
              });
          }
        } catch (e) {
          // show error
        }
      }

      this.loading = false;
    },

    async readFile(file: File): Promise<Document> {
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.addEventListener('load', (e) => {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString((e.target?.result as string), 'application/xml');
          const errorNode = xmlDoc.querySelector('parsererror');

          if (errorNode) {
            reject(new Error(errorNode.textContent as string));
          }

          resolve(xmlDoc);
        });

        reader.readAsText(file);
      });
    },

    validateFile(drawIODoc: Document): void {
      if (!drawIODoc.querySelector('mxfile')) {
        throw new Error('Element "mxfile" is not found');
      }

      if (!drawIODoc.querySelectorAll('diagram')) {
        throw new Error('Element "diagram" is not found');
      }
    },

    async download(): Promise<void> {
      const { form3 } = this.$refs;

      if (form3) {
        const { valid } = await (form3 as Validator).validate();

        if (!valid) {
          return;
        }
      }

      if (this.drawIODoc) {
        try {
          const { data } = await api.renderTemplate(this.drawIODoc, this.diagrams);
          const {
            template,
            error,
          } = data;

          if (error) {
            this.error = error;
            return;
          }

          if (template) {
            const [name, extension] = this.file?.name.split('.') || ['labs64-diagrams', 'drawio'];

            const blob = new Blob([template], { type: 'application/octet-stream' });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${name}-labs64.${extension}`;
            link.click();
            URL.revokeObjectURL(url);
          }
        } catch (e: any) {
          this.error = e.message;
        }
      }
    },

    addDiagram(diagram?: Partial<Page>): Page {
      const newDiagram = {
        title: diagram?.title || `Page ${this.diagrams.length + 1}`,
        id: diagram?.id,
        layers: diagram?.layers || [],
        collapsed: diagram?.collapsed || true,
      };

      this.diagrams.push(newDiagram);

      return newDiagram;
    },

    removeDiagram(diagram: Page | number): void {
      const index = (typeof diagram === 'number') ? diagram : this.diagrams.indexOf(diagram);

      if (index > -1) {
        this.diagrams.splice(index, 1);
      }
    },

    addLayer(
      diagram: Page,
      layer?: Partial<Layer>,
    ): Layer {
      const newLayer = {
        title: layer?.title || `Layer ${diagram.layers.length + 1}`,
        id: layer?.id,
        visible: layer?.visible || false,
        locked: layer?.locked || false,
        edit: layer?.edit || false,
      };

      diagram.layers.push(newLayer);

      return newLayer;
    },

    removeLayer(page: Page, layer: Layer | number): void {
      const index = (typeof layer === 'number') ? layer : page.layers.indexOf(layer);

      if (index > -1) {
        page.layers.splice(index, 1);
      }
    },

    markActionElement(key: string, item: ComboboxItem) {
      this.drawIODoc?.querySelectorAll(`[${this.$options.LABS64_PREFIX}="${key}]"]`)
        .forEach((el: Element) => {
          el.removeAttribute(this.$options.LABS64_PREFIX);
        });

      if (item) {
        const el = this.drawIODoc?.getElementById(item.id);

        if (el) {
          el.setAttribute(this.$options.LABS64_PREFIX, key);
        }
      }
    },

    isDiagramLayersVisible(diagram: Page): boolean {
      return diagram.layers.some((layer) => layer.visible);
    },

    toggleDiagramLayersVisibility(diagram: Page): void {
      const isVisible = this.isDiagramLayersVisible(diagram);

      diagram.layers.forEach((layer) => {
        // eslint-disable-next-line no-param-reassign
        layer.visible = !isVisible;
      });
    },

    isDiagramLayersLocked(diagram: Page): boolean {
      return diagram.layers.some((layer) => layer.locked);
    },

    toggleDiagramLayersLock(diagram: Page): void {
      const isLocked = this.isDiagramLayersLocked(diagram);

      diagram.layers.forEach((layer) => {
        // eslint-disable-next-line no-param-reassign
        layer.locked = !isLocked;
      });
    },

    toggleAllLayersVisibility() {
      const isVisible = this.isLayersVisible;

      this.diagrams.forEach((diagram) => {
        diagram.layers.forEach((layer) => {
          // eslint-disable-next-line no-param-reassign
          layer.visible = !isVisible;
        });
      });
    },

    toggleAllLayersLock() {
      const isLocked = this.isLayersLocked;

      this.diagrams.forEach((diagram) => {
        diagram.layers.forEach((layer) => {
          // eslint-disable-next-line no-param-reassign
          layer.locked = !isLocked;
        });
      });
    },
  },
});
