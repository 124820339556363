// vue
import { createApp } from 'vue';

// pwa
import '@/registerServiceWorker';

// vue-router
import router from '@/router';

// vuex
import store from '@/store';

// plugins
import vuetify from '@/plugins/vuetify';

import App from '@/App.vue';

createApp(App)
  .use(store)
  .use(router)
  .use(vuetify)
  .mount('#app');
