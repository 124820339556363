import axios, { AxiosResponse } from 'axios';
import { Page } from '@/interfaces/Page';

export default {
  renderTemplate(doc: Document, pages: Page[]):
    Promise<AxiosResponse<{ template?: string, error?: string }>> {
    return axios.post(process.env.VUE_APP_LAMBDA_URL, {
      pages,
      template: doc.documentElement.outerHTML,
    });
  },
};
